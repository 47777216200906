<template>
  <draggable
    v-model="value"
    dense
    class="mb-2"
    handle=".handle"
  >
    <drug-regimen
      v-for="(regimen, index) in value"
      :key="index"
      :regimen="regimen"
      :count="index + 1"
      :uuid="uuid"
      :organisms="organisms"
      :resistance-genes="resistanceGenes"
      :relevant-resistance="relevantResistance"
      :diagnoses="diagnoses"
      :allergies="allergies"
      :pediatric="pediatric"
      :age-bracket="ageBracket"
      :source="source"
      :gender="gender"
      :pregnant="pregnant"
      :breastfeeding="breastfeeding"
      :formulary="formulary"
      :method="method"
      draggable
      xreadonly="!$auth.check({'interpretations': ['*', 'edit']})"
      @update="$emit('update')"
      @deleteRegimen="value.splice(index, 1)"
    />
    <v-list-item class="mt-n6 ml-n3">
      <v-btn
        x-small
        rounded
        color="primary lighten-2"
        @click="addAlternativeOption"
      >
        <v-icon
          left
          small
          class="ml-n1 mr-1"
        >
          mdi-plus
        </v-icon>
        Add Option
      </v-btn>
    </v-list-item>
  </draggable>
</template>
<script>
export default {
  components: {
    DrugRegimen: () => import('@/components/drug/DrugRegimen.vue'),
    draggable: () => import('vuedraggable'),
  },
  props: {
    value: {
      type: Array,
      default: () => ([{
        indication_id: null,
        regimens: [{
          dosage: null,
          duration: null,
          drug: {},
          pivot: {
            connector: null,
          },
        }],
      }]),
    },
    uuid: {
      type: String,
      required: true,
    },
    organisms: {
      type: Array,
      default: () => ([]),
    },
    resistanceGenes: {
      type: Array,
      default: () => ([]),
    },
    relevantResistance: {
      type: Array,
      default: () => ([]),
    },
    diagnoses: {
      type: Array,
      default: () => ([]),
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    source: {
      type: String,
      default: null,
    },
    gender: {
      type: String,
      default: null,
    },
    formulary: {
      type: Object,
      default: () => ({}),
    },
    method: {
      type: String,
      default: null,
    },
    ageBracket: {
      type: String,
      default: 'adult',
    },
    pediatric: Boolean,
    pregnant: Boolean,
    breastfeeding: Boolean,
  },
  data: () => ({
  }),
  methods: {
    addAlternativeOption () {
      this.value.push({
        indication_id: null,
        regimens: [{
          dosage: null,
          duration: null,
          drug: {},
          pivot: {
            connector: null,
          },
        }],
      })
    },
  },
}
</script>
